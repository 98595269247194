<template>
    <div id="BGMBackground">
      <div id="Profile" ref="circle"></div>
      <button id="play-pause-btn" @click="playPause"></button>
      <button id="next-song-btn" @click="nextSong"></button>
    </div>
    <audio id="myAudio" ref="audio" :src="audioFiles[currentSongIndex]"></audio>
  </template>
  
<script>
  import { mapState, mapMutations } from 'vuex';
  export default {
    name: 'BGMPlayer',
    
    computed: {
      ...mapState(['audioFiles', 'currentSongIndex', 'isPlaying']),
      audio() {
        return this.$refs.audio;
      },
      circle() {
        return this.$refs.circle;
      },
      playPauseBtn() {
        return document.getElementById('play-pause-btn');
      },
      nextSongBtn() {
        return document.getElementById('next-song-btn');
      },
    },
    methods: {
      ...mapMutations(['setPlayingState', 'setCurrentSongIndex']),
      playPause() {
      if (this.audio.paused) {
          this.audio.play();
          this.circle.style.animationPlayState = 'running';
          this.setPlayingState(true);
          this.playPauseBtn.style.backgroundPosition = '-30px 0px';
      } else {
          this.audio.pause();
          this.circle.style.animationPlayState = 'paused';
          this.setPlayingState(false);
          this.playPauseBtn.style.backgroundPosition = '0px 0px';
      }

      this.audio.addEventListener('ended', () => {
          this.nextSong();
      });
      },

      nextSong() {
        this.setCurrentSongIndex((this.currentSongIndex + 1) % this.audioFiles.length);
        this.circle.style.animationPlayState = 'running';
        this.setPlayingState(true);
        this.audio.addEventListener('canplay', () => {
          this.audio.play();
          this.playPauseBtn.style.backgroundPosition = '-30px 0px';
      });
      },
        
      playBGMOnce() {
        this.audio.play();
        this.setPlayingState(true);
        this.playPauseBtn.style.backgroundPosition = '-30px 0px';
  
        this.audio.addEventListener('ended', () => {
          this.nextSong();
        });
  
        window.removeEventListener('wheel', this.playBGMOnce);
      },
    },
      mounted() {
        window.addEventListener('wheel', this.playBGMOnce);
      },
      beforeUnmount() {
        window.removeEventListener('wheel', this.playBGMOnce);
      },
  };
</script>
  
<style>
    /* Set the size of the player to 100px and create a frosted glass effect rectangle */
    #BGMBackground {
    position: fixed;
    z-index: 999;
    width: 150px;
    height: 60px;
    left: 20px;
    top: 90vh;
    opacity: 0.6;
    box-sizing: border-box;
    /* BAThemeS1/Transparency/SilverTrans/Silver40 */
    background: rgba(199, 199, 204, 0.3);
    /* BAThemeS1/Transparency/SilverTrans/Silver40 */
    border: 1px solid rgba(199, 199, 204, 0.3);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 15px 30px 15px 30px;;
    animation: fadeinout 9s ease-in-out infinite;
    }

    @keyframes fadeinout {
    0% {
        opacity: 0.2;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0.2;
    }
    }

    #Profile{
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: linear-gradient(90deg, rgba(0,255,255,1) 0%, rgba(255,0,0,1) 100%);
    position: absolute;
    left: 7%;
    top: 9px;
    bottom: 10px;
    animation: rotate 10s linear infinite;
    animation-play-state: paused;
    }
    @keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
    }

    /* Style the play/pause button to show two vertical bars when playing and a triangle when paused */
    #play-pause-btn {
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    background-color: transparent;
    background-image: url("../assets/Pictures/Play_Pause.png");
    background-position: 0px, 0px;
    background-size: cover;
    cursor: pointer;
    position: absolute;
    left: 43%;
    top: 15px;
    bottom: 15px;
    transition: all 0.3s ease-in-out;
    }



    /* Style the next song button */
    #next-song-btn {
    position: absolute;
    width: 17px;
    height: 20px;
    border: none;
    background-color: transparent;
    background-image: url("../assets/Pictures/Next.png");
    background-size: cover;
    outline: none;
    cursor: pointer;
    left:75%;
    top: 20px;
    bottom: 20px;
    }

    @media screen and (max-width: 743px){
      #BGMBackground {
        top: 76vh;
      }
    }


</style>