import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
//import WorldView from '../views/WorldView.vue'
//import MineView from '../views/MineView.vue'
//import ArtverseView from '../views/ArtverseView.vue'
//import GNFTView from '../views/GNFTView.vue'
//import GloryPage from '../views/GloryView.vue'
//import WikiBook from '../components/WikiBook.vue'
//import NFTmint from '../components/NFTmint.vue'
//import Test from '@/views/TestViews.vue';
import store from '../store';



const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { title: 'BLANKONART | Welcome' },
  },
  {
    path: '/worldview',
    name: 'worldview',
    component: () => import('../views/WorldView.vue'),
    meta: { title: 'BLANKONART | Worldview' },
  },
  {
    path: '/artverse',
    name: 'artverse',
    component: () => import('../views/ArtverseView.vue'),
    meta: { title: 'BLANKONART | Artverse' },
  },
  {
    path: '/gnft',
    name: 'gnft',
    component: () => import('../views/GNFTView.vue'),
    meta: { title: 'BLANKONART | Gene NFT' },
  },
  {
    path: '/glory',
    name: 'glory',
    component: () => import('../views/GloryView.vue'),
    meta: { title: 'BLANKONART | Our Glory' },
  },
  {
    path: '/mineview',
    name: 'mineview',
    component: () => import('@/views/MineView.vue'),
    meta: { title: 'BLANKONART | Mine' },
  },
  {
    path: '/WikiBook',
    name: 'wikibook',
    component: () => import('../components/WikiBook.vue'),
    meta: { title: 'BLANKONART | WikiBook' },
  },
  {
    path: '/mintnft',
    name: 'NFTmint',
    component: () => import('../components/NFTmint.vue'),
    meta: { title: 'BLANKONART | Mint NFT' },
  },

  {
    path: '/Artverse_Background',
    name: 'Artverse',
    component: () => import('../components/Artverse_Background.vue') 
  },
  {
    path: '/Art_GNFTFinal',
    name: 'ArtGNFTFinal',
    component: () => import('../components/Art_GNFTFinal.vue') 
  },

  { 
    path: '/gnftworld/:position/:id', 
    name: 'GNFTWorld', 
    component: () => import('../components/Art_GNFTWorld.vue') 
  },
  { 
    path: '/gnfttotal/:position/:id',
    name: 'Art_GNFTTotal',
    component: () => import('../components/Art_GNFTTotal.vue') ,
  },
  { 
    path: '/GNFTMicro/:position/:id', 
    name: 'GNFTMicro', 
    component: () => import('../components/Art_GNFTMicro.vue') ,
    props: true,
  },

];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 添加全局前置守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'BLANK ON ART';
  store.commit('SET_LOADING', true);
  next();
});

router.afterEach(() => {
  window.scrollTo(0, 0)
  setTimeout(() => {
    store.commit('SET_LOADING', false); // 在导航结束后设置 isLoading 为 false
  }, 2000);
});


export default router
