<template>
  <BGMPlayer />
  <div id="app">
    <Navbar />
    <LoadingData v-if="isLoading" />
    <LightMode/>
  </div>
</template>

<script>
import Navbar from "@/components/Nav.vue";
import BGMPlayer from '@/components/BGMPlayer.vue';
import LoadingData from './components/LoadingData.vue';
import LightMode from './components/LightMode.vue';

export default {
  components: {
    Navbar,
    BGMPlayer,
    LoadingData,
    LightMode,
  },
  data() {
    return {
      isLoading: true, //isLoading
    };
  },
  mounted() {
    // if loading just needs 2s.
    setTimeout(() => {
      this.isLoading = false; // When finished,  set isLoading = false
    }, 2000);
  },
};
</script>

<style>
#app {
  font-family: Quicksand, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #f7f7f7;
  background-color: #03090e;
  cursor: url('~@/assets/cursor.png'), auto;
}

body {
  cursor: url('~@/assets/cursor.png'), auto;
}


</style>
