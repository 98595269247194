import { createApp } from 'vue';
import Web3Plugin from './web3';
import App from './App.vue';
import router from './router';
import store from './store';
import OuterLink from '@/components/OuterLink.vue';
import LoadingData from './components/LoadingData.vue';
import { Masonry, MasonryItem } from 'masonry-layout';
import './global.css';




const app = createApp(App);
app.component('VueMasonry', Masonry);
app.component('VueMasonryItem', MasonryItem);
app.use(store);
app.use(router);
app.component('outer-link', OuterLink);
app.component('LoadingData', LoadingData);
app.use(Web3Plugin);
app.mount('#app');
