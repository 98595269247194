import { createStore } from "vuex";
import Web3 from "web3";

const savedAddress = localStorage.getItem("address");
const savedBalance = localStorage.getItem("balance");

export default createStore({
  state: {
    web3: null,
    address: savedAddress ? JSON.parse(savedAddress) : null,
    balance: savedBalance ? JSON.parse(savedBalance) : null,
    isConnected: false,
    isLoading: false, 
    // Add state for BGM player
    audioFiles: [
      require('../assets/Audio/Clair de Lune Debussy.mp3'),
      require('../assets/Audio/The Deepest Ocean.mp3'),
      require('../assets/Audio/Out of Time.mp3'),
      require('../assets/Audio/Jahzza-TheLake.mp3'),
      require('../assets/Audio/Spirit.mp3'),
      require('../assets/Audio/Etude No 8 in F-Sharp Minor, Op 32.mp3'),
      require('../assets/Audio/A Long Walk.mp3'),
      require('../assets/Audio/Consistency.mp3'),
      require('../assets/Audio/Ancestral Spirits.mp3'),
      require('../assets/Audio/The Four Seasons, Winter - Vivaldi.mp3'),
      require('../assets/Audio/ef4.mp3'),
      // ...other mp3s
    ],
    currentSongIndex: 0,
    isPlaying: false,
  },
  mutations: {
    setAddress(state, newAddress) {
      state.address = newAddress;
      localStorage.setItem("address", JSON.stringify(newAddress));
    },
    setBalance(state, newBalance) {
      state.balance = newBalance;
      localStorage.setItem("balance", JSON.stringify(newBalance));
    },
    setWeb3(state, web3) {
      state.web3 = web3;
    },
    setConnected(state, isConnected) {
      state.isConnected = isConnected;
    },
     // Add mutations for BGM player
     setPlayingState(state, playing) {
      state.isPlaying = playing;
    },
    setCurrentSongIndex(state, index) {
      state.currentSongIndex = index;
    },
    SET_LOADING(state, payload) {
      state.isLoading = payload;
    },
  },
  actions: {
    async connectWallet({ commit }) {
      if (typeof window.ethereum !== "undefined") {
        const provider = window.ethereum;
        const web3 = new Web3(provider);
        commit("setWeb3", web3);

        try {
          const accounts = await provider.request({
            method: "eth_requestAccounts",
          });

          commit("setAddress", accounts[0]);
          commit("setConnected", true);

          const balance = await web3.eth.getBalance(accounts[0]);
          commit("setBalance", balance);
        } catch (error) {
          if (process.env.NODE_ENV === 'development') {
            console.error("Error connecting wallet:", error);
          }          
        }
      } else {
        console.log("No web3 provider detected. Please install MetaMask.");
      }
    },
    async getBalance({ state }) {
      if (!state.isConnected || !state.address) {
        return null;
      }
    
      try {
        const web3 = state.web3;
        const balance = await web3.eth.getBalance(state.address);
        return balance;
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error('Error getting balance:', error);
        }
        return null;
      }
    },
    async loadBalanceFromLocalStorage({ commit }) {
      const savedBalance = localStorage.getItem("balance");
      if (savedBalance) {
        commit("setBalance", JSON.parse(savedBalance));
      }
    },
    
  },
  modules: {},
});
