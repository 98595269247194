<template>  
    <Wallet ref="walletRef" />
    <div class="dropdown">
      <button class="dropbtn" @click="myFunction"></button>
      <div class="dropdown-content" id="myDropdown" @mouseleave="closeDropdown">
        <router-link to="/">Welcome</router-link>
        <router-link to="/artverse">Artverse</router-link>
        <router-link to="/gnft">Gene NFT</router-link>
        <router-link to="/worldview">Worldview</router-link>
        <router-link to="/glory">Glory</router-link>
        <button class="connect-btn" @click="handleButtonClick" :class="{ 'connect-btn': true, 'yellow-text': isMinePage }">{{ buttonText }}</button>

      </div>
    </div>
  </template>
  
  <script>
  import Wallet from "@/components/wallet.vue";


  export default {
    name: 'AppDropdown',
    components: {
      Wallet,
    },
    methods: {
      myFunction() {
        document.getElementById("myDropdown").classList.toggle("show");
      },
      closeDropdown() {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains('show')) {
            openDropdown.classList.remove('show');
          }
        }
      },
      async handleButtonClick() {
      if (this.buttonText === 'Connect') {
        await this.$refs.walletRef.showPopup();
        // 如果文本为 "Connect"，调用钱包连接方法
        await this.$store.dispatch("connectWallet");
      } else {
        // 如果文本为 "Mine"，跳转到 mine 页面
        this.$router.push("/mineview");
      }
    },
    },

    mounted() {
      window.addEventListener('click', (event) => {
        if (!event.target.matches('.dropbtn')) {
          this.closeDropdown();
        }
      });
    },
    beforeUnmount() {
      window.removeEventListener('click', this.closeDropdown);
    },
    computed: {
      buttonText() {
        return this.$store.state.address ? "Mine" : "Connect";
      },
      isMinePage() {
        return this.$route.path === "/mineview";
      },
    },

  }
  </script>
  
  <!-- Add your existing style section here. -->
<style>
    .dropdown {
        display: none;
        position: absolute;
        right: 3%;
        top: 18px;
        width: 50px;
        height: 50px;
        border-radius: 15px;
        z-index: 9999;

    }
    @media (max-width: 999px) {
        .dropdown {
        display: block;
        }
    } 

    .dropbtn {
      background: url("../assets/Pictures//Menus.png");
      background-size: 70%;
      background-position: center;
      background-repeat: no-repeat;
      border: none;
      cursor: pointer;
      position: absolute;
      width: 100%;
      height: 40px;
      left: 0;
    }
    
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: rgba(15, 47, 79, 0.8);
      min-width: 140px;
      box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
      z-index: 1;
      top: 45px;
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.01em;
      text-decoration: none;
      align-items: center;
      text-align: center;
      color: #C7C7CC;
      cursor: pointer;
      right:0;
      border-radius: 15px;
    }
    
    .dropdown-content a {
      color: #C7C7CC;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
      right:0%;
      left: 0;
    }
    
    .dropdown-content a:hover {
      background-color: #C7C7CC;
      color: #0F2F4F;
      border-radius: 15px;
        
    }

    button.connect-btn {
      background-color: transparent;
      opacity: 60%;
      min-width: 140px;
      top: 45px;
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 60px;
      letter-spacing: 0.01em;
      text-decoration: none;
      align-items: center;
      text-align: center;
      color: #C7C7CC;
      cursor: pointer;
      right:0;
      border: none;
      border-radius: 0 0 15px 15px;
    }
    button.connect-btn:hover {
      background-color: #C7C7CC;
      color: #0F2F4F;
      border-radius: 15px;
        
    }
    .yellow-text {
      color: #B07736;
    }

    
    .show {
        display: block;
    }
    
 </style>