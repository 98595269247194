<template>
    <a :href="href" :target="target"><slot></slot></a>
  </template>
  
  <script>
  export default {
    name: 'OuterLink',
    props: {
      href: {
        type: String,
        required: true
      },
      target: {
        type: String,
        default: '_blank'
      }
    }
  };
</script>
  