<template>
  <div ref="popup" class="popup">
    <div class="qrword">Scan with BLANKONART App</div>
    <div class="Connect">Connect by</div>
    <div class="buttons">
      <button @click="connectWallet('beart')">BE-ART LOGIN</button>
      <button @click="connectWallet('metamask')">METAMASK</button>
      <button @click="connectWallet('walletconnect')">WALLETCONNECT</button>
    </div>
    <div class="line"></div>
    <div class="qrcode"></div>
    <div class="close" @click="hidePopup">
      <div class="close-line"></div>
      <div class="close-line"></div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "WalletPopup",
  data() {
    return {
      isConnected: false,
    };
  },
  methods: {
    showPopup() {
    const popup = document.querySelector(".popup");
    popup.style.display = "flex";

    return new Promise((resolve) => {
      const connectButton = document.querySelector(".connect-button");
      connectButton.addEventListener("click", () => {
        this.hidePopup();
        resolve();
      });
    });
    },
    hidePopup() {
      console.log("Hiding popup");
      const popup = document.querySelector(".popup");
      popup.style.display = "none";
    },

    async connectWallet(walletType) {
      // 隐藏弹出框
      this.hidePopup();
      if (walletType === "metamask") {
        const isMetamaskInstalled = typeof window.ethereum !== "undefined";
        if (!isMetamaskInstalled) {
          window.open("https://metamask.io", "_blank");
          return;
        }
        if (this.isConnected) return;

        this.isConnected = true;
        const store = useStore();

        try {
          const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
          const networkId = await window.ethereum.request({ method: "net_version" });

          if (process.env.NODE_ENV === 'development') {
            console.log("Connected account:", accounts[0]);
            console.log("Network ID:", networkId);
          }
          // 根据网络ID设置不同的网络链接
          let networkUrl;
          switch (networkId) {
            case "1":
              networkUrl = 'https://mainnet.infura.io/v3/899d42704f314d6c800241da4192cad9';
              break;
            case "3":
              networkUrl = "https://ropsten.infura.io/v3/YOUR-PROJECT-ID";
              break;
            case "4":
              networkUrl = "https://rinkeby.infura.io/v3/YOUR-PROJECT-ID";
              break;
            case "5":
              networkUrl = "https://goerli.infura.io/v3/YOUR-PROJECT-ID";
              break;
            case "42":
              networkUrl = "https://kovan.infura.io/v3/YOUR-PROJECT-ID";
              break;
            default:
              if (process.env.NODE_ENV === 'development') {
                console.error("Unsupported network");
              }
              return;
          }

          // 使用新的网络链接更新Web3实例
          window.web3.setProvider(networkUrl);

          // 获取钱包地址并更新 store 中的地址
          store.commit("setAddress", accounts[0]);

          // 隐藏弹出框
          this.hidePopup();

          // 获取余额
          const balance = await window.web3.eth.getBalance(accounts[0]);
          console.log("Balance:", balance);


        } catch (error) {
          this.isConnected = false;
          if (process.env.NODE_ENV === 'development') {
            console.error("Error connecting to wallet:", error.message);
          }
        }
      } else if (walletType === "beart") {
        // 连接 BE-ART LOGIN 钱包的逻辑
      } else if (walletType === "walletconnect") {
        // 连接 WALLETCONNECT 钱包的逻辑
      }
    }
  },
};
</script>




<style> 

.popup {
  display: none; /* set the popup to be hidden by default */
  position: fixed; /* make the popup fixed to the window */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 440px;
  background-color: #03090E;
  border-radius: 20px;
  box-shadow: 0px 0px 40px rgba(14, 47, 79, 0.3);
  /* add any other styles you need */
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

  .popup .Connect{
    position: absolute;
    top: 11%;
    left: 79px;
    color: #0F2F4F;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
  }

  .popup .buttons {
    display: flex;
    margin-top:80px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 80px;

  }

  .popup .buttons button {
    width: 380px;
    height: 70px;
    background-color: #03090E;
    color: #C7C7CC;
    border: 1px solid rgba(199, 199, 204, 0.5);
    border-radius: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
  }

  .popup .buttons button:hover {
    border: 2px solid #0F2F4F;
    box-shadow: 0px 0px 10px rgba(13, 75, 57, 0.3);
  }

  .popup .qrcode {
    width: 200px;
    height: 200px;
    margin-top:60px;
    background-image: url("../assets/Pictures/qrcode.png");
    background-size: cover;
    border-radius: 5px;
    margin-left: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .popup .qrword {
    position: absolute;
    top: 360px;
    left:645px;
    color: #65686D;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 9px;
  }



  .popup .line {
    height: 50%;
    width: 1px;
    margin-top:7%;
    background-color: #0D4B39;;
  }

  .popup .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background-color: #03090E;
    box-sizing: border-box;
    border: 1px solid #C7C7CC;
    cursor: pointer;
    border-radius: 50%;
  }

  .popup .close:before{
    content: '';
    position: absolute;
    left:13px;
    height: 26px;
    border: 1px solid rgba(199, 199, 204, 0.6);
    transform: rotate(45deg);
  }
  .popup .close:after {
    content: '';
    position: absolute;
    left:13px;
    height: 26px;
    border: 1px solid rgba(199, 199, 204, 0.6);
    transform: rotate(-45deg);
  }



  @media screen and (max-width: 990px)  {
    .popup {
      width: 400px;
      height: 500px;
    }
    .popup .Connect{
      top: 5%;
      left: 10%;
    }
    .popup .buttons {
      margin-top: 70px;
      margin-right: 0px;
    }
    .popup .buttons button {
      width: 300px;
    }
    .popup .qrcode {
      display: none;
    }
    .popup .line {
      display: none;
    }

  }

  @media screen and (max-width: 510px)  {
    .popup {
      width: 300px;
      height: 400px;
    }
    .popup .Connect{
      top: 5%;
      left: 10%;
    }
    .popup .buttons {
      margin-top: 70px;
      margin-right: 0px;
    }
    .popup .buttons button {
      width: 260px;
    }
    .popup .qrcode {
      display: none;
    }
    .popup .line {
      display: none;
    }






  }
</style>