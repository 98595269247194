<template>
    <Wallet ref="walletRef" />
    <nav v-show="!hideNav" class="navbar" :class="{ 'nav-transparent': isScrollingDown }">
    
      <router-link to="/">Welcome</router-link> 
      <router-link to="/artverse">Artverse</router-link> 
      <router-link to="/gnft">Gene NFT</router-link>
      <router-link to="/worldview">Worldview</router-link> 
      <router-link to="/glory">Glory</router-link> 
      
      <div class="logo"></div>
      <div class="right">
        <button
            v-if="!isConnected"
            class="nav-button"
            ref="connectButton"
            @click="handleButtonClick"
        >
            <span>Connect</span>
        </button>
        <button
            v-else
            id="minespan"
            class="nav-button"
            @click="navigateToMine"
        >
            <span>Mine</span>
        </button>
            <Circle></Circle>	
      </div>
      <AppDropdown></AppDropdown>
    </nav>
    <router-view></router-view>
    <wallet ref="walletComponent" @wallet-connected="updateButtonText"></wallet>
  </template>
  
  <script>
  import Wallet from "@/components/wallet.vue";
  import AppDropdown from "@/components/Dropdown.vue";
  import { ref, onMounted, onUnmounted } from 'vue';

  export default {
    name: "NavBar",
    components: {
      Wallet,
      AppDropdown,
    },
    data() {
      return {
        isScrollingDown: false,
        // 其他数据保持不变
      };
    },
    
    computed: {
      isConnected() {
        return !!this.$store.state.address;
      },
    },
    mounted() {
        if (window.ethereum) {
        window.ethereum.on("accountsChanged", this.handleAccountsChanged);
        }
    },
    beforeUnmount() {
        if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", this.handleAccountsChanged);
        }
    },
    methods: {
      async handleButtonClick() {
        if (!this.isConnected) {
          await this.$refs.walletRef.showPopup();
          await this.$store.dispatch("connectWallet");
          this.isConnected = true;
        } else {
          this.$emit("mine");
        }
      },
      navigateToMine() {
        this.$router.push("/mintnft");
      },
      handleAccountsChanged(accounts) {
        if (accounts.length === 0) {
          // 用户断开钱包连接
          this.$store.commit("setAddress", null);
          localStorage.removeItem("address");
        } else {
          // 用户更改了钱包地址
          this.$store.commit("setAddress", accounts[0]);
          localStorage.setItem("address", JSON.stringify(accounts[0]));
        }
      },

      handleScroll() {
        const isSmallScreen = window.matchMedia('(max-width: 999px)').matches;
        if (!isSmallScreen) {
          this.hideNav = false;
          return;
        }

        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
        this.isScrollingDown = currentScrollTop > this.lastScrollTop;
        this.lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
      },
    },
    setup() {
      const hideNav = ref(false);
      let lastScrollTop = 0;

      const handleScroll = () => {
        // 检查视窗宽度是否小于 999px
        const isSmallScreen = window.matchMedia('(max-width: 999px)').matches;

        // 如果屏幕宽度大于 999px，返回
        if (!isSmallScreen) {
          hideNav.value = false;
          return;
        }

        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

        // 向下滚动时，隐藏导航栏
        if (currentScrollTop > lastScrollTop) {
          hideNav.value = true;
        } else if (currentScrollTop  + 50 < lastScrollTop ) {
          // 向上滚动时，显示导航栏
          hideNav.value = false;
        }

        lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
      };

      onMounted(() => {
        window.addEventListener('scroll', handleScroll);
      });

      onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll);
      });

      return {
        hideNav,
      };
    },
  }
</script>


<style>
  .nav-transparent {
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
  }
  .nav:hover {
    opacity: 1 !important;
  }
  nav .logo {
      position: absolute;
      width: 215px;
      height: 60px;
      left: 1%;
      top: 12px;
      background: url("../assets/Pictures/BAlogo.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
  }   
  nav .right {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px;
      gap: 30px;
      position: fixed;
      width: 200px;
      height: 40px;
      right: 2%;
      top: 18px;
  }
  /* Button */
  .nav-button {
      /* Group 266 */
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 40px;
      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
      /* Rectangle 2 */
      box-sizing: border-box;
      position: absolute;
      left: 0%;
      right: 30%;
      top: 0px;
      bottom: 0%;
      /* BAThemeS1/Spring-MainColor/Silver
      Color Gray V2
      */
      border: 1px solid #C7C7CC;
      transition: border-color 0.5s ease;
      border-radius: 20px 5px 20px 5px;
      background-color: transparent;
      cursor: pointer;
  }
  .nav-button:hover {
      border-color: #0F2F4F;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
  }
  .nav-button:hover span {
      color: #FFFFFF;
  }



  /* Log in：Connect */
  .nav-button span {

      width: auto;
      left: 20%;
      top: 5%;
      bottom: 5%;
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0em;
      color: #C7C7CC;
      transition: color 0.3s ease-in-out;
  }

  /* Circle */
  nav .right Circle {
      /* Inside auto layout */
      width: 40px;
      height: 40px;
      position: relative;
      left: 80%;
      top: 0%;
      bottom: 36px;
      border-radius: 50%;
      box-sizing:  content-box;
      background: url("../assets/Pictures/BA_Spring.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding: 0px;
      z-index: 999;
      transition: box-shadow 0.3s ease-in-out;
      cursor: pointer;
  }
  nav .right Circle:hover {
      box-shadow: 0px 0px 0px 1px #0B6623 inset, 0px 0px 1px 1px #0B6623;
  } 

  nav {
      position: fixed;
      width: 100%;
      height: 75px;
      left: 0%;
      top: 0%;
      background: #03090E;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      z-index: 9999;
      font-family: "Quicksand";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.01em;
      align-items: center;
      text-align: center;
      text-decoration: none;
      opacity: 0.9;
  }

  nav a:hover {
      color: #0F2F4F;
  }

  nav a.router-link-exact-active {
      color: #B07736;
      text-decoration: none;
  }
  .navbar  {
    transform: translateY(-10%);
    transition: transform 0.3s ease-in-out;
  }


@media screen and (max-width: 999px) {
  nav a {
      display: none;
      position: relative;
      top:25%;
      left: 20%;
      padding: 20px;
      font-weight: 500;
      font-size: 19px;
      line-height: 36px;
      letter-spacing: 0.01em;
      align-items: center;
      text-align: center;
      color: #C7C7CC;
      text-decoration: none;
  }
  nav .right {
      display: none;
  }

}

@media screen and (min-width: 1000px) and (max-width: 1399px) {
  nav a {
      position: relative;
      top:25%;
      left: 5%;
      padding: 10px;
      font-weight: 500;
      font-size: 20px;
      line-height: 36px;
      letter-spacing: 0.01em;
      align-items: center;
      text-align: center;
      color: #C7C7CC;
      text-decoration: none;
  }
  .nav-button {
      width: 110px;
      height: 38px;
      top: 1px;
      right: 35%;
      left: 15%;
  }
  .nav-button span {
      left: 18%;
      font-size: 18px;
  }

}

@media screen and (min-width: 1400px) and (max-width: 1619px) {
  nav a {
      position: relative;
      top:25%;
      left: 12%;
      padding: 15px;
      font-weight: 500;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.01em;
      align-items: center;
      text-align: center;
      color: #C7C7CC;
      text-decoration: none;
  }
  .nav-button {
      width: 115px;
      height: 38px;
      top: 1px;
      right: 35%;
      left: 12%;
  }
  .nav-button span {
      left: 18%;
      font-size: 18px;
  }
}

@media screen and (min-width: 1620px) and (max-width: 1919px) {
  nav a {
      position: relative;
      top:25%;
      left: 13%;
      padding: 17px;
      font-weight: 550;
      font-size: 23px;
      line-height: 36px;
      letter-spacing: 0.01em;
      align-items: center;
      text-align: center;
      color: #C7C7CC;
      text-decoration: none;
  }
}

@media screen and (min-width: 1920px) and (max-width: 2199px) {
  nav a {
      position: relative;
      top:25%;
      left: 14%;
      padding: 20px;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.01em;
      align-items: center;
      text-align: center;
      color: #C7C7CC;
      text-decoration: none;
  }

}
@media screen and (min-width: 2200px) {
  nav a {
      position: relative;
      top:25%;
      left: 20%;
      padding: 20px;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.01em;
      align-items: center;
      text-align: center;
      color: #C7C7CC;
      text-decoration: none;
  }

}

</style>
  

