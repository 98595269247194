import Web3 from 'web3';

export default {
  install: (app) => {
    let web3;

    if (typeof window.ethereum !== 'undefined') {
      web3 = new Web3(window.ethereum);
      app.config.globalProperties.$web3 = web3;
    } else {
      console.error('Ethereum browser extension not detected. Please install MetaMask.');
    }
  },
};
